import React from "react";
import {
    StyledTextInput,
    StyledSelectInput,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { AvGroup } from "availity-reactstrap-validation";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import { Input } from "reactstrap";

import { CustomCardBody } from "@components/Common/CustomCardStyled";
import { DynamicTableWrapperContext } from "@components/Common";

import "react-phone-input-2/lib/style.css";
import { isEmailValid } from "@helpers/general";
import { mutate } from "swr";
import { setEmailModif } from "@store/features/accessSlice";
import { useDispatch } from "react-redux";

type FormBodyPropsType = {
    user: Usertype;
    setUser: React.Dispatch<React.SetStateAction<Usertype>>;
    // eslint-disable-next-line no-unused-vars
    addToRefs: (el: any) => void;
    isInvalidTelephone: boolean;
    isInvalidEmail: boolean;
    setIsInvalidEmail: React.Dispatch<React.SetStateAction<boolean>>;
    verifyEmailError: boolean;
    setVerifyEmailError: React.Dispatch<React.SetStateAction<boolean>>;
};
export function FormBody({
    user,
    setUser,
    addToRefs,
    isInvalidTelephone,
    isInvalidEmail,
    setIsInvalidEmail,
    verifyEmailError,
    setVerifyEmailError,
}: FormBodyPropsType) {
    const { t } = useTranslation();
    const i18nextLng = localStorage.getItem("i18nextLng") as string;
    const [profiles, setProfiles] = React.useState<any>([]);
    const dispatch = useDispatch();
    const customerAccountId = localStorage.getItem("customerAccountId");
    async function getProfiles() {
        const response: Response = await fetch(
            `${process.env.REACT_APP_ACCESS_API_URL}profiles?customerAccountUUid=${customerAccountId}&profileStatus=Activé&customerLang=${i18nextLng}`
        );
        const result = await response.json();
        const Dataprofiles: any = [];

        result?.data?.forEach((obj: any) => {
            if (!obj.isProfileAdmin) {
                Dataprofiles.push({
                    uid: obj.uid,
                    label: obj.Désignation,
                    value: obj.Désignation,
                });
            }
        });

        setProfiles(Dataprofiles);
    }

    React.useEffect(() => {
        getProfiles();
        // eslint-disable-next-line
    }, []);

    const selectedCountryCode = localStorage.getItem("selectedCountryCode");

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    async function sendVerificationEmailRequest(email: string) {
        const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/checkEmail?email=${email}`;
      //  if (dynamicTableWrapperContext?.dataTable.data.length > 0) {
            try {
                // const dataTable =
                //     dynamicTableWrapperContext?.dataTable.data.filter(
                //         (item: any) => item.Email === email
                //     );

                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "GET",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                setVerifyEmailError(true);
                              //  throw Error();
                            }
                            // } else if (dataTable.length > 0) {
                            //     setVerifyEmailError(true);
                            // }
                             else {
                                setVerifyEmailError(false);
                            }
                        })
                );
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
            }
        //}
    }

    async function handleEmailInputOnBlurEvent(
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        const userEmail = e.target.value;
        if (dynamicTableWrapperContext?.rowData?.Email !== userEmail) {
            await sendVerificationEmailRequest(userEmail);
        } 
        dispatch(setEmailModif(false));
        setIsInvalidEmail(false);
        setIsInvalidEmail(!isEmailValid(userEmail));
    }
    return (
        <CustomCardBody
            istablet="true"
            isvisible="true"
            className="card-body__clz"
        >
            <AvGroup>
                <StyledLabel htmlFor="Nom" className="required__clz mt-3">
                    {t("Last name")}
                </StyledLabel>
                <StyledTextInput
                    className="ctl_inp_name"
                    autoComplete="off"
                    id="Nom"
                    name="nom"
                    placeholder={t("To input")}
                    type="text"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setUser({
                            ...user,
                            Nom: event.target.value.trim(),
                        });
                    }}
                    value={user.Nom}
                    autoFocus
                    validate={{
                        required: {
                            value: user.Nom === "",
                            // eslint-disable-next-line sonarjs/no-duplicate-string
                            errorMessage: t("This field is required"),
                        },
                    }}
                    innerRef={addToRefs}
                />
            </AvGroup>
            <AvGroup>
                <StyledLabel htmlFor="Prenom" className="required__clz mt-3">
                    {t("First name")}
                </StyledLabel>
                <StyledTextInput
                    className="ctl_inp_firstName"
                    autoComplete="off"
                    id="Prénom"
                    name="Prenom"
                    placeholder={t("To input")}
                    type="text"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setUser({
                            ...user,
                            Prénom: event.target.value.trim(),
                        });
                    }}
                    value={user.Prénom}
                    validate={{
                        required: {
                            value: user.Prénom === "",
                            errorMessage: t("This field is required"),
                        },
                    }}
                    innerRef={addToRefs}
                />
            </AvGroup>
            <AvGroup>
                <StyledLabel htmlFor="Email" className="required__clz mt-3">
                    {t("Email")}
                </StyledLabel>
                <StyledTextInput
                    className="ctl_inp_email"
                    autoComplete="off"
                    id="Email"
                    name="Email"
                    placeholder={t("To input")}
                    type="text"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setIsInvalidEmail(false);
                        setVerifyEmailError(false);
                        setUser({
                            ...user,
                            Email: event.target.value.trim(),
                        });
                        handleEmailInputOnBlurEvent(event);
                    }}
                    value={user.Email}
                    validate={{
                        required: {
                            value: user.Email === "",
                            errorMessage: t("This field is required"),
                        },
                    }}
                    innerRef={addToRefs}
                    onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleEmailInputOnBlurEvent(event);
                    }}
                />
                {isInvalidEmail && user.Email ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Email is not valid")!}
                    </div>
                ) : null}
                {verifyEmailError ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("This email address is already in use")}
                    </div>
                ) : null}
            </AvGroup>
            <div
                className="d-flex flex-row align-items-center justify-content-between"
                style={{ marginLeft: "5px" }}
            >
                <AvGroup>
                    <StyledLabel htmlFor="Telephone1" className="mt-3">
                        {`${t("Telephone ")} 1`}
                    </StyledLabel>
                    <PhoneInput
                        buttonClass="ctl_btn_firstPhoneNumber"
                        inputClass="ctl_inp_firstPhoneNumber"
                        country={selectedCountryCode as string}
                        value={user?.["Téléphone 1"]}
                        onChange={(phone: any) => {
                            setUser({
                                ...user,
                                "Téléphone 1": phone,
                            });
                        }}
                        placeholder={t("To input")}
                    />
                </AvGroup>
                <AvGroup>
                    <StyledLabel htmlFor="Telephone2" className=" mt-3">
                        {`${t("Telephone ")} 2`}
                    </StyledLabel>
                    <PhoneInput
                        buttonClass="ctl_btn_secondPhoneNumber"
                        inputClass="ctl_inp_secondPhoneNumber"
                        country={selectedCountryCode as string}
                        value={user?.["Téléphone 2"]}
                        onChange={(phone) =>
                            setUser({
                                ...user,
                                "Téléphone 2": phone,
                            })
                        }
                        placeholder={t("To input")}
                    />
                </AvGroup>
            </div>
            {isInvalidTelephone === true ? (
                <div
                    style={{
                        width: "100%",
                        marginTop: "0.25rem",
                        fontSize: "80%",
                        color: "#f46a6a",
                    }}
                >
                    {t("invalid phone number")}
                </div>
            ) : null}
            <AvGroup>
                <StyledLabel htmlFor="text" className="mt-3">
                    {t("Profile")}
                </StyledLabel>
                {dynamicTableWrapperContext?.rowData?.isAdmin === true ? (
                    <Input
                        value={user.Profil as string}
                        name="profil"
                        id="profil"
                        disabled
                        nooptionsmessage={t("No profiles")}
                    />
                ) : (
                    <StyledSelectInput
                        isClearable={
                            dynamicTableWrapperContext?.rowData?.Nom === ""
                        }
                        value={
                            user.Profil === ""
                                ? null
                                : {
                                      label: user.Profil,
                                      value: user.Profil,
                                  }
                        }
                        onChange={(e: any) => {
                            setUser({
                                ...user,
                                Profil: e !== null ? e.label : "",
                                selectedProfil: e !== null && e,
                            });
                        }}
                        placeholder={t("Select")}
                        options={profiles}
                        name="profil"
                        nooptionsmessage={t("No profiles")}
                        id="Profil"
                        className="ctl_inp_profil"
                        ref={addToRefs}
                    />
                )}
            </AvGroup>
            {/* Display set it to none because we will need Bailse on the future it just disable for now */}
            <AvGroup style={{ display: "none" }}>
                <StyledLabel htmlFor="text" className=" mt-3">
                    {t("Tag")}
                </StyledLabel>
                <StyledSelectInput
                    className="ctl_inp_tags"
                    placeholder={t("Select")}
                    name="Balise"
                    nooptionsmessage={t("No tags")}
                />
            </AvGroup>
            <AvGroup>
                <StyledLabel htmlFor="text" className=" mt-3">
                    {t("Commentary")}
                </StyledLabel>
                <StyledTextInput
                    className="ctl_inp_comment"
                    maxLength={2000}
                    autoComplete="off"
                    id="Commentaire"
                    name="Commentaire"
                    placeholder={t("To input")}
                    type="textarea"
                    onChange={(e: any) => {
                        setUser({
                            ...user,
                            Commentaire: e.target.value,
                        });
                    }}
                    value={user.Commentaire}
                />
            </AvGroup>
        </CustomCardBody>
    );
}
