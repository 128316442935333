import React from "react";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { setUserChildsAccess } from "@store/features/accessSlice";

import HeaderComponent from "@components/VerticalLayout/HeaderComponent";
import { RootState } from "@store/index";

import AllowAccess from "./AllowAccess";
import AccessActionColumn from "./AccessActionColumn";

type ManageAccessModalContentPropsType = {
    userId: string;
    selectedRows: any[];
};

export function ManageAccessModalContent({
    userId,
    selectedRows,
}: ManageAccessModalContentPropsType) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const customerAccountId = localStorage.getItem("customerAccountId");
    const uuids = localStorage.getItem("uuids");
    const [data, setData] = React.useState({});

    // eslint-disable-next no-unused-vars
    const [selectedAccessRows] = React.useState<any[]>([]);

    const [isDataUpdated, setIsDataUpdated] = React.useState<boolean | number>(
        false
    );
    const updatedData = useSelector(
        (state: RootState) => state.accessSlice.updatedData
    );

    const [selectedRows1, setSelectedRows] = React.useState<any[]>([]);

    const [url, setUrl] = React.useState(
        `${process.env.REACT_APP_ACCESS_API_URL}/customer-account-childs?customerAccountUUid=${customerAccountId}`
    );

    async function getMultipleAccessData() {
        if (selectedRows !== undefined && selectedRows?.length > 0) {
            const ids = selectedRows?.map((row) => {
                return row.ID;
            });
            const response = await fetch(
                `${
                    process.env.REACT_APP_ACCESS_API_URL
                }/multiple-access-accounts?customerAccountUUid=${customerAccountId}&userAccountIds=${ids.join()}&uuids=${uuids}`,
                {}
            );

            const data = await response.json();
            if (userId === undefined) {
                setData({ data: data.response });
                const localData = (data as any).data!.map((child: any) => {
                    return {
                        id: child.id,
                        authorization: child.access_authorization,
                    };
                });

                dispatch(setUserChildsAccess(localData));
            }
        }
    }

    const arrayOfCustomColumns: any[] = [
        {
            indexOFColumn: 99,
            columnName: "Autoriser l'accès",
            customJsx: AllowAccess,
        },
    ];

    React.useEffect(() => {
        if (
            data !== undefined &&
            /*
            selectedRows !== undefined &&
            && userId !== undefined 
            */

            Object.keys(data).length > 0 &&
            (selectedRows === undefined || selectedRows?.length === 0)
        ) {
            const localData = (data as any).data!.map((child: any) => {
                return {
                    id: child.id,
                    authorization: child.access_authorization,
                };
            });
            localStorage.setItem("userAccessInfo", JSON.stringify(localData));

            dispatch(setUserChildsAccess(localData));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    React.useEffect(() => {
        if (userId !== undefined) {
            setUrl((prevState) => {
                // eslint-disable-next-line  sonarjs/prefer-immediate-return , no-multi-assign
                const newState = (prevState += `&userAccountId=${userId}`);

                return newState;
            });
        }
        if (
            selectedRows !== undefined &&
            selectedRows?.length > 0 &&
            userId === undefined
        ) {
            const ids = selectedRows.map((row) => {
                return row.ID;
            });
            setUrl(
                `${
                    process.env.REACT_APP_ACCESS_API_URL
                }/multiple-access-accounts?customerAccountUUid=${customerAccountId}&userAccountIds=${ids.join()}&uuids=${uuids}`
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        getMultipleAccessData();
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (updatedData?.isAccessDataUpdated && selectedAccessRows.length > 0) {
            setData({
                data: selectedAccessRows,
                // othersData: data.othersData
            });

            setIsDataUpdated(!isDataUpdated);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedData]);

    return (
        <React.Fragment>
            <HeaderComponent
                title={t(
                    "A user can only access a customer account if access has been granted to him."
                )}
            />
            <DynamicTable
            maxHeight={450}
            minHeight={450}
                setDataIsUpdated={setIsDataUpdated}
                //  dataIsUpdated={isDataUpdated}
                // canSort
                name="table-access"
                // canResize
                showGlobalFilter
                showFilter
                canSelect
                customSelect
                url={url}
                // eslint-disable-next-line react/no-unstable-nested-components
                setSelectedRows={setSelectedRows}
                customJsxSideFilterButton={
                    <AccessActionColumn
                        selectedRows={selectedRows1}
                        setSelectedRows={setSelectedRows}
                    />
                }
                arrayOfCustomColumns={arrayOfCustomColumns}
                setData={setData}
            />
        </React.Fragment>
    );
}
